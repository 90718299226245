<template>
  <div class="py-6">
    <v-app-bar flat color="transparent" class="py-0" v-show="isMainPage">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue" sm>{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'settings.dashboard' }"
          >Back</router-link
        >
      </v-toolbar-title>
    </v-app-bar>
    <v-app-bar flat color="transparent" class="mt-0 py-0">
      <v-toolbar-title class="headline d-flex font-weight-bold"
        >Settings</v-toolbar-title
      >
    </v-app-bar>
    <router-view class="ml-4" />
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiChevronLeft } from '@mdi/js'

export default {
  name: 'SettingsPage',

  mixins: [ControlsMixin],

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft
      }
    }
  },

  computed: {
    isMainPage() {
      if (
        this.$route.name === 'settings.pages.privacy' ||
        this.$route.name === 'settings.pages.terms-of-service'
      ) {
        return true
      }
      return false
    }
  },

  components: {
    AppBarNavIcon
  }
}
</script>
